<template>
  <v-card v-if="value" width="50%" max-width="450px" class="position-relative">
    <SearchPlaceResult :value="value" simple />
    <v-btn @click="$emit('click:reset')" class="close-btn" icon small>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import SearchPlaceResult from "./Result/SearchPlaceResult.vue";
export default {
  components: { SearchPlaceResult },
  props: {},
  data: () => ({ value: null }),
  computed: {},
  methods: {
    open(value) {
      this.value = value;
    },
    close() {
      this.value = null;
    },
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 10px;
  right: 0px;
}
</style>
